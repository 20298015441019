import referFriendPageComponent from '../components/referFriendPage/.component.json';
import { REFER_FRIEND_PAGE_ID } from '../components/referFriendPage/constants';
import referralPageComponent from '../components/referralPage/.component.json';
import { REFERRAL_PAGE_ID, REFERRAL_PAGE_URI } from '../components/referralPage/constants';
import { PageConfig } from './types';

export const referFriendPage: PageConfig = {
  id: REFER_FRIEND_PAGE_ID,
  uri: 'refer-friends',
  titleKey: 'pages.title.refer-friend',
  widgetId: referFriendPageComponent.id,
  addToMenu: true,
  presets: {
    desktop: 'variants-lcep6zn51',
    mobile: 'variants-lcsphqdj',
  },
  classicEditorSize: {
    stretched: true,
    width: 980,
    height: 500,
  },
  responsiveEditorWidth: {
    type: 'percentage',
    value: 100,
  },
};

export const referralPage: PageConfig = {
  id: REFERRAL_PAGE_ID,
  uri: REFERRAL_PAGE_URI,
  titleKey: 'pages.title.referral',
  widgetId: referralPageComponent.id,
  addToMenu: false,
  verticalMargin: 20,
  presets: {
    desktop: 'variants-lcspkeh21',
    mobile: 'variants-lcspofmc',
  },
  classicEditorSize: {
    stretched: false,
    width: 980,
    height: 500,
  },
  responsiveEditorWidth: {
    type: 'percentage',
    value: 70,
  },
};
