import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import {
  configureBoxComponent,
  configureComponentAsHidden,
  configureGlobalDesignTabs,
  configureImageComponent,
  configurePageWidget,
  GfppAction,
  HelpId,
} from '../../editor';
import { ElementId as CopyTextElementId } from '../copyText/constants';
import { ElementId as ContentElementId } from '../referFriendPageContent/constants';
import { ElementId, ViewStateId } from './constants';
import { presets } from './editor';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  configurePageWidget(widgetBuilder, {
    flowAPI,
    displayName: t('pages.title.refer-friend'),
    nickname: 'referFriendPageWidget',
    helpId: HelpId.ReferFriendsPage,
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('refer-friend-page.design.title'),
      presetsTitle: t('refer-friend-page.design.presets-title'),
    });

    widgetDesignBuilder.setPresets(presets);
  });

  widgetBuilder.configureWidgetDesign(
    configureGlobalDesignTabs([
      {
        label: t('refer-friend-page.design.tab.main-title'),
        roles: [ContentElementId.TitleText],
      },
      {
        label: t('refer-friend-page.design.tab.subtitle'),
        roles: [ContentElementId.SubtitleText],
      },
      {
        label: t('refer-friend-page.design.tab.body-text'),
        roles: [ContentElementId.DescriptionText, ContentElementId.CopyLinkText, ContentElementId.ShareText],
      },
      {
        label: t('refer-friend-page.design.tab.button'),
        roles: [ContentElementId.LoginButton, CopyTextElementId.CopyButton],
      },
      {
        label: t('refer-friend-page.design.tab.secondary-text'),
        roles: [ContentElementId.ExplanationsText],
      },
      {
        label: t('refer-friend-page.design.tab.notification-background'),
        roles: [ElementId.NotificationBannerRoot],
      },
      {
        label: t('refer-friend-page.design.tab.box-background'),
        roles: [ElementId.ContentContainer],
      },
      {
        label: t('refer-friend-page.design.tab.image-background'),
        roles: [ElementId.ImageContainer],
      },
    ]),
  );

  widgetBuilder.configureWidgetViewState(ViewStateId.LoggedIn, (viewStateBuilder) => {
    viewStateBuilder.title(t('refer-friend-page.state.logged-in'));
  });

  widgetBuilder.configureWidgetViewState(ViewStateId.LoggedOut, (viewStateBuilder) => {
    viewStateBuilder.title(t('refer-friend-page.state.logged-out'));
  });

  widgetBuilder.configureWidgetViewState(ViewStateId.Notification, (viewStateBuilder) => {
    viewStateBuilder.title(t('refer-friend-page.state.notification'));
  });

  widgetBuilder.gfpp().set('layout', { actionId: GfppAction.OpenLayoutPanel });

  widgetBuilder.configureConnectedComponents(ElementId.CookieManager, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.ProgramStates, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.AvailableState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.NotAvailableState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.StatusText, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.SpacingContainer, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.Content, configureComponentAsHidden());

  widgetBuilder.configureConnectedComponents(ElementId.ContentContainer, (builder) => {
    configureBoxComponent({
      displayName: t('refer-friend-page.element.content'),
    })(builder);

    builder.gfpp().set('layout', { actionId: GfppAction.OpenLayoutPanel });
  });

  widgetBuilder.configureConnectedComponents(
    ElementId.ImageContainer,
    configureBoxComponent({
      displayName: t('refer-friend-page.element.image-container'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.Image,
    configureImageComponent({
      displayName: t('refer-friend-page.element.image'),
    }),
  );
};
