import { PagesBuilder } from '@wix/yoshi-flow-editor';

import { Event } from '../constants';
import { referFriendPage, referralPage } from '../pages';
import { EditorScriptContext } from '../types';

export const configurePages = ({ flowAPI }: EditorScriptContext, pagesBuilder: PagesBuilder) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;

  pagesBuilder.configureState(referFriendPage.id, (stateBuilder) => {
    // NOTE: EditorX shows "Rename" page action (without title) and does nothing when clicked..
    // You can still rename the page by double clicking however even without this action defined.
    if (!isEditorX) {
      stateBuilder.addAction({
        type: 'page_rename',
      });
    }

    stateBuilder
      .set({
        icon: 'page',
        orderIndex: 2,
      })
      .addAction({
        title: t('pages.action.delete'),
        event: Event.DeleteApp,
        icon: 'deleteAction',
      });
  });

  pagesBuilder.configureState(referralPage.id, (stateBuilder) => {
    if (!isEditorX) {
      stateBuilder.addAction({
        type: 'page_rename',
      });
    }

    // Page info tab is disabled for referral page (so users don't have an option to set
    // this page as homepage)
    stateBuilder.addSettingsTab(
      {
        type: 'layout',
      } as any,
      {
        type: 'permissions',
      } as any,
      {
        title: t('pages.settings.seo-basics'),
        type: 'seo',
      },
    );

    stateBuilder.set({
      icon: 'page',
      orderIndex: 1,
    });
  });

  pagesBuilder.addSettingsTab(
    {
      type: 'page_info',
    } as any,
    {
      type: 'layout',
    } as any,
    {
      type: 'permissions',
    } as any,
    {
      // NOTE: For some reason built-in "seo" action does not show default title unless we provide it.
      title: t('pages.settings.seo-basics'),
      type: 'seo',
    },
  );
};
