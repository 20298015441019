import { EditorScriptFlowAPI, WidgetBuilder } from '@wix/yoshi-flow-editor';

import { GfppAction } from '../constants';

interface ConfigureParams {
  flowAPI: EditorScriptFlowAPI;
  displayName: string;
  nickname: string;
  helpId: string;
}

export const configurePageWidget = (
  widgetBuilder: WidgetBuilder,
  { flowAPI, displayName, nickname, helpId }: ConfigureParams,
) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    displayName,
    nickname,
  });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
    preventHide: true,
  });

  widgetBuilder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('add', { actionId: GfppAction.OpenElementsPanel })
    .set('upgrade', { behavior: 'SITE_UPGRADE' })
    .set('help', { id: helpId })
    .set('mainAction1', {
      label: t('gfpp.manage-program'),
      actionId: GfppAction.ManageProgram,
    })
    .set('mainAction2', {
      label: t('gfpp.switch-state'),
      actionId: GfppAction.OpenSwitchStatePanel,
    });
};
