export const MOBILE_BREAKPOINT_ID = 'wix-referrals-mobile-breakpoint';
export const TABLET_BREAKPOINT_ID = 'wix-referrals-tablet-breakpoint';

export const defaultBreakpoints = {
  type: 'BreakpointsData' as const,
  values: [
    {
      type: 'BreakpointRange' as const,
      id: MOBILE_BREAKPOINT_ID,
      min: 320,
      max: 750,
    },
    {
      type: 'BreakpointRange' as const,
      id: TABLET_BREAKPOINT_ID,
      min: 751,
      max: 1000,
    },
  ],
};
