import { EditorScriptContext, PageConfig } from '../../types';
import { createSectionDefinition } from './createSectionDefinition';
import { createWidgetDefinition } from './createWidgetDefinition';

export { defaultBreakpoints } from './breakpoints';

// Wrapper container (section) and widget components for responsive editor
export const createResponsiveDefinitions = (context: EditorScriptContext, pageConfig: PageConfig) => {
  return createSectionDefinition([createWidgetDefinition(context, pageConfig)], pageConfig);
};
