import { EditorScriptFlowAPI, WidgetBuilder } from '@wix/yoshi-flow-editor';

type ComponentBuilderFn = Parameters<WidgetBuilder['configureConnectedComponents']>[1];

interface ConfigureParams {
  flowAPI: EditorScriptFlowAPI;
  displayName?: string;
  allowLabelEdit?: boolean;
  allowIconEdit?: boolean;
}

export const configureButtonComponent =
  ({ flowAPI, displayName, allowLabelEdit = true, allowIconEdit = true }: ConfigureParams): ComponentBuilderFn =>
  (builder) => {
    const { t } = flowAPI.translations;

    if (displayName) {
      builder.set({ displayName });
    }

    builder.behavior().set({ preventHide: true });
    builder.gfpp().set('connect', { behavior: 'HIDE' }).set('link', { behavior: 'HIDE' });
    builder.panel<'StylableButton'>('settings').configureControls({
      ...(!allowIconEdit && {
        icon: {
          hidden: true,
        },
      }),
      link: {
        hidden: true,
      },
      ...(!allowLabelEdit && {
        label: {
          hidden: true,
        },
      }),
    } as any);

    if (!allowLabelEdit) {
      builder.gfpp().set('mainAction1', { label: t('gfpp.change-design'), actionType: 'design', actionId: '' });
    }
  };
