import { EditorScriptContext, PageConfig } from '../../types';
import { MOBILE_BREAKPOINT_ID, TABLET_BREAKPOINT_ID } from './breakpoints';

export const createWidgetDefinition = (
  { flowAPI }: EditorScriptContext,
  { widgetId, presets, responsiveEditorWidth, verticalMargin }: PageConfig,
) => {
  const { appDefinitionId } = flowAPI.environment;

  return {
    type: 'RefComponent',
    componentType: 'wysiwyg.viewer.components.RefComponent',
    skin: 'skins.core.InlineSkin',
    components: [],
    layoutResponsive: {
      type: 'LayoutData',
      componentLayouts: [
        {
          type: 'ComponentLayout',
          height: {
            type: 'auto',
          },
          width: responsiveEditorWidth,
          minWidth: {
            type: 'px',
            value: 0,
          },
          minHeight: {
            type: 'px',
            value: 0,
          },
          hidden: false,
        },
      ],
      containerLayouts: [
        {
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      ],
      itemLayouts: [
        {
          type: 'GridItemLayout',
          gridArea: {
            columnStart: 1,
            columnEnd: 2,
            rowStart: 1,
            rowEnd: 2,
          },
          alignSelf: 'center',
          justifySelf: 'center',
          margins: {
            top: { type: 'px', value: verticalMargin ?? 0 },
            right: { type: 'px', value: 0 },
            bottom: { type: 'px', value: verticalMargin ?? 0 },
            left: { type: 'px', value: 0 },
          },
        },
      ],
    },
    style: 'ref1',
    activeModes: {},
    data: {
      type: 'WidgetRef',
      appDefinitionId,
      widgetId,
    },
    presets: {
      layout: presets.desktop,
      style: presets.desktop,
      type: 'PresetData',
    },
    scopedPresets: {
      [MOBILE_BREAKPOINT_ID]: {
        type: 'PresetData',
        layout: presets.mobile,
        style: presets.mobile,
      },
      [TABLET_BREAKPOINT_ID]: {
        type: 'PresetData',
        layout: presets.mobile,
        style: presets.mobile,
      },
    },
  };
};
