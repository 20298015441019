import { EditorSubType, PageRef } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext, PageConfig } from '../types';
import { addBlocksWidget } from './addBlocksWidget';
import { createResponsiveDefinitions, defaultBreakpoints } from './responsiveDefinitions';

export const createPage = async (context: EditorScriptContext, pageConfig: PageConfig): Promise<PageRef> => {
  const { editorSDK, flowAPI, origin } = context;
  const { t } = flowAPI.translations;
  const { appDefinitionId } = flowAPI.environment;
  const isEditorX = origin.subType === EditorSubType.EditorX;

  const appPages = await editorSDK.pages.getApplicationPages('');
  const existingPage = appPages.find(({ tpaPageId }) => tpaPageId === pageConfig.id);

  // NOTE: It's possible to trigger app install multiple times from EditorX app market. Avoid adding
  // the page if it already exists.
  if (existingPage) {
    return editorSDK.components.getById('', { id: existingPage.id! });
  }

  const components = isEditorX ? [createResponsiveDefinitions(context, pageConfig)] : undefined;

  const pageRef = await editorSDK.pages.add('', {
    title: t(pageConfig.titleKey),
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      ...(!!components && { components: components as any }),
      breakpoints: isEditorX ? defaultBreakpoints : undefined,
      data: {
        managingAppDefId: appDefinitionId,
        appDefinitionId,
        tpaPageId: pageConfig.id,
        pageUriSEO: pageConfig.uri,
      },
    },
    shouldAddMenuItem: pageConfig.addToMenu,
    shouldNavigateToPage: false,
  });

  if (!isEditorX) {
    await addBlocksWidget(context, {
      pageRef,
      pageConfig,
    });
  }

  return pageRef;
};
