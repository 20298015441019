import { WidgetBuilder } from '@wix/yoshi-flow-editor';

type WidgetDesignManifestBuilderFn = Parameters<WidgetBuilder['configureWidgetDesign']>[0];

interface GlobalDesignTab {
  label: string;
  roles: string[];
}

export const configureGlobalDesignTabs =
  (tabs: GlobalDesignTab[]): WidgetDesignManifestBuilderFn =>
  (widgetDesignBuilder) => {
    const designTabsBuilder = widgetDesignBuilder.tabs();

    for (const { label, roles } of tabs) {
      designTabsBuilder.addTab((tabBuilder) => {
        tabBuilder.set({ label }).groups().set({ roles });
      });
    }
  };
