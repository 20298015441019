import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { configureButtonComponent, configureTextComponent, GfppAction } from '../../editor';
import { ElementId } from './constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    displayName: t('refer-friend-page.element-category.notification'),
    nickname: 'notificationBanner',
  });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
    preventHide: true,
  });

  widgetBuilder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('add', { behavior: 'HIDE' })
    .set('layout', { actionId: GfppAction.OpenLayoutPanel });

  widgetBuilder.configureConnectedComponents(
    ElementId.NotificationTitleText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.notificationTitle'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.NotificationDescriptionText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.notificationDescription'),
    }),
  );

  widgetBuilder.configureConnectedComponents(ElementId.GetRewardButton, configureButtonComponent({ flowAPI }));
};
