import { WidgetBuilder } from '@wix/yoshi-flow-editor';

type ComponentBuilderFn = Parameters<WidgetBuilder['configureConnectedComponents']>[1];

interface ConfigureParams {
  displayName?: string;
}

export const configureImageComponent =
  ({ displayName }: ConfigureParams = {}): ComponentBuilderFn =>
  (builder) => {
    if (displayName) {
      builder.set({ displayName });
    }

    builder.gfpp().set('connect', { behavior: 'HIDE' }).set('link', { behavior: 'HIDE' });
  };
