import { WidgetBuilder } from '@wix/yoshi-flow-editor';

type ComponentBuilderFn = Parameters<WidgetBuilder['configureConnectedComponents']>[1];

export const configureComponentAsHidden = (): ComponentBuilderFn => (builder) => {
  builder.behavior().set({
    closed: {
      selectable: false,
      hideFromHierarchy: true,
    },
  });
};
