import { PageConfig } from '../../types';

export const createSectionDefinition = (components: unknown[], pageConfig: PageConfig) => {
  return {
    type: 'Container',
    componentType: 'responsive.components.Section',
    components,
    scopedLayouts: {},
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
      },
      itemLayout: {
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
      type: 'SingleLayoutData',
    },
    scopedStyles: {},
    style: {
      type: 'ComponentStyle',
      style: {
        properties: {
          'alpha-bg': '0',
          bg: 'color_11',
        },
        propertiesSource: {
          'alpha-bg': 'value',
          bg: 'theme',
        },
        groups: {},
      },
      componentClassName: 'responsive.components.Section',
      pageId: pageConfig.id,
      compId: '',
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.area.RectangleArea',
    },
    activeModes: {},
  };
};
