import { EditorScriptContext } from './types';

// Set state for each application page (used to show different page actions/settings/icons etc)
export const setStatesForPages = async ({ editorSDK }: EditorScriptContext) => {
  const appPages = await editorSDK.document.pages.getApplicationPages('');

  const pagesState = appPages.reduce((result, { id, tpaPageId }) => {
    if (!id || !tpaPageId) {
      return result;
    }

    return {
      ...result,
      [tpaPageId]: [{ id }], // Each page has its own state based on tpaPageId
    };
  }, {});

  await editorSDK.document.pages.setState('', {
    state: pagesState,
  });
};
