import { AddComponentHandler, RemoveComponentHandler } from '@wix/editor-platform-sdk-types';
import { ComponentRef } from '@wix/yoshi-flow-editor';

import { EditorScriptContext } from '../types';
import { getElementsPanelData } from './getElementsPanelData';

export const openElementsPanel = async (widgetRef: ComponentRef, context: EditorScriptContext): Promise<void> => {
  const { editorSDK } = context;
  const { elementsData, categoriesData } = await getElementsPanelData(widgetRef, context);

  if (!elementsData.length) {
    return;
  }

  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetHostRef] = await editorSDK.components.getAncestors('', {
      componentRef: widgetRef,
    });

    const collapsedRefComponents = await editorSDK.components.refComponents.getCollapsedRefComponents('', {
      componentRef: widgetHostRef,
      includeInnerCollapsed: true,
    } as any);

    const collapsedRefComponent = collapsedRefComponents.find((component) => component.role === role);
    return collapsedRefComponent?.componentRef;
  };

  const getComponentToHide = async (componentRef: ComponentRef) => {
    const componentType = await editorSDK.components.getType('', { componentRef });

    return componentType.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('', { componentRef }))[0]
      : componentRef;
  };

  const showComponent = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('', { componentRef });
  };

  const hideComponent = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent('', { componentRef });
  };

  const addComponentHandler: AddComponentHandler = async ({ role }, componentRef) => {
    const componentToShow = componentRef || (await getCollapsedRefComponentByRole(role));
    if (componentToShow) {
      await showComponent(componentToShow);
    }
  };

  const removeComponentHandler: RemoveComponentHandler = async (componentRef) => {
    const componentToHide = await getComponentToHide(componentRef);
    await hideComponent(componentToHide);
  };

  await editorSDK.editor.openElementsPanel('', {
    widgetRef,
    categoriesData,
    elementsData,
    addComponentHandler,
    removeComponentHandler,
  });
};
