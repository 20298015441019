import { WidgetBuilder } from '@wix/yoshi-flow-editor';

type ComponentBuilderFn = Parameters<WidgetBuilder['configureConnectedComponents']>[1];

interface ConfigureParams {
  displayName?: string;
  allowTextEdit?: boolean;
}

export const configureTextComponent =
  ({ displayName, allowTextEdit = false }: ConfigureParams = {}): ComponentBuilderFn =>
  (builder) => {
    if (displayName) {
      builder.set({ displayName });
    }

    if (!allowTextEdit) {
      builder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
    }

    builder.gfpp().set('connect', { behavior: 'HIDE' });
  };
