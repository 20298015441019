import { ComponentRef } from '@wix/yoshi-flow-editor';

import { EditorScriptContext } from '../../../editor';
import { designVariablesByPreset } from './presets';

export const changePreset = async (widgetRef: ComponentRef, presetId: string, context: EditorScriptContext) => {
  const { editorSDK } = context;
  const [widgetHostRef] = await editorSDK.components.getAncestors('', { componentRef: widgetRef });
  const designVariables = designVariablesByPreset[presetId];

  if (designVariables) {
    await editorSDK.application.appStudioWidgets.designVariables.set('', {
      widgetRef,
      newValues: { ...designVariables },
    });
  }

  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef: widgetHostRef,
    stylePresetId: presetId,
    layoutPresetId: presetId,
  });
};
