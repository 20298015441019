import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import {
  configureButtonComponent,
  configureComponentAsHidden,
  configureLineComponent,
  configureTextComponent,
} from '../../editor';
import { ElementId } from './constants';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    nickname: 'referFriendPageContent',
  });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
    preventHide: true,
  });

  widgetBuilder.gfpp().set('connect', { behavior: 'HIDE' }).set('add', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(ElementId.UserStates, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.LoggedInState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.LoggedOutState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.CopyLink, configureComponentAsHidden());

  widgetBuilder.configureConnectedComponents(
    ElementId.TitleText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.title'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.SubtitleText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.subtitle'),
      allowTextEdit: true,
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.DescriptionText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.description'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.ExplanationsText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.explanations'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.Divider,
    configureLineComponent({
      displayName: t('refer-friend-page.element.divider'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.CopyLinkText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.copy-link-text'),
      allowTextEdit: true,
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.ShareText,
    configureTextComponent({
      displayName: t('refer-friend-page.element.share-text'),
      allowTextEdit: true,
    }),
  );

  widgetBuilder.configureConnectedComponents(ElementId.SocialBar, (builder) => {
    builder.set({
      displayName: t('refer-friend-page.element.social-bar'),
    });
    builder.gfpp().set('connect', { behavior: 'HIDE' }).set('mainAction2', { behavior: 'HIDE' });
  });

  widgetBuilder.configureConnectedComponents(ElementId.LoginButton, configureButtonComponent({ flowAPI }));
};
